/*
* {
  border: 1px solid black;
}
*/

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

.container {
  height: 100%;
}

/* putting .navbar style here to make it easy to see the 5%/95% split
 * the navbar itself will have its own style cascade
 */
.navbar {
  height: 5%;
}

.two-col-container{
  height: 95%;
}

.two-col-left, .two-col-right {
  height: 100%;
}
