.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gray;
  padding: 0 25px;
  background-color: #CFDCE6;
}

.navbar-mobile {
  align-items: center;
  border: 1px solid gray;
  padding: 0 25px;
  background-color: #CFDCE6;
}

.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-contact {
}
