.search-bar {
  padding: 0px 20px;
  display: flex;
  flex: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  border: 1px solid grey;
  background-color: #d9d9d9;
}

.MapListSearch input {
  margin-right: 5px;
}

.MapListSearch {
  display: flex;
}

.MapListFilter {
  display: flex;
}

.MapListSearch label, .MapListFilter label {
  font-weight: bold;
  padding-right: 5px;
}

.MapList {
  height: 95%;
  overflow-x:hidden;
  overflow-y:scroll;
}

.MapList .room-detail:not(.selected):hover {
        background-color: #d9d9d9;
}

.MapList .selected {
  background-color: #dff1ff;
}

.MapList .room-detail {
  display: flex;
  flex-flow: wrap;
  /* padding: 10px; */
  border: 1px solid gray;
  width: 100%;
  align-items: center;
  padding-left: 20px;
}

.MapList .room-detail-cost {
  flex: 0 0 20%;
  font-size:1.3em;
}

.MapList .room-detail-name {
  flex: 0 0 50%;
  font-weight: bold;
}

.MapList .room-detail-neighborhood {
  flex: 0 0 30%;
}

.MapList .room-detail-info {
  padding: 0 50px;
}
